// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-blog-fonboarding-js": () => import("./../../../src/pages/blog/fonboarding.js" /* webpackChunkName: "component---src-pages-blog-fonboarding-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-rebranding-a-message-from-our-ceo-js": () => import("./../../../src/pages/blog/rebranding-a-message-from-our-ceo.js" /* webpackChunkName: "component---src-pages-blog-rebranding-a-message-from-our-ceo-js" */),
  "component---src-pages-blog-the-major-causes-of-project-delays-js": () => import("./../../../src/pages/blog/the-major-causes-of-project-delays.js" /* webpackChunkName: "component---src-pages-blog-the-major-causes-of-project-delays-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-careers-project-manager-js": () => import("./../../../src/pages/careers/project-manager.js" /* webpackChunkName: "component---src-pages-careers-project-manager-js" */),
  "component---src-pages-careers-senior-react-native-developer-js": () => import("./../../../src/pages/careers/senior-react-native-developer.js" /* webpackChunkName: "component---src-pages-careers-senior-react-native-developer-js" */),
  "component---src-pages-careers-ui-ux-designer-medior-js": () => import("./../../../src/pages/careers/ui-ux-designer-medior.js" /* webpackChunkName: "component---src-pages-careers-ui-ux-designer-medior-js" */),
  "component---src-pages-case-studies-aegis-js": () => import("./../../../src/pages/case-studies/aegis.js" /* webpackChunkName: "component---src-pages-case-studies-aegis-js" */),
  "component---src-pages-case-studies-e-aro-js": () => import("./../../../src/pages/case-studies/e-aro.js" /* webpackChunkName: "component---src-pages-case-studies-e-aro-js" */),
  "component---src-pages-case-studies-quality-control-js": () => import("./../../../src/pages/case-studies/quality-control.js" /* webpackChunkName: "component---src-pages-case-studies-quality-control-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-it-services-application-development-js": () => import("./../../../src/pages/it-services/application-development.js" /* webpackChunkName: "component---src-pages-it-services-application-development-js" */),
  "component---src-pages-it-services-application-re-engineering-js": () => import("./../../../src/pages/it-services/application-re-engineering.js" /* webpackChunkName: "component---src-pages-it-services-application-re-engineering-js" */),
  "component---src-pages-it-services-blockchain-development-js": () => import("./../../../src/pages/it-services/blockchain-development.js" /* webpackChunkName: "component---src-pages-it-services-blockchain-development-js" */),
  "component---src-pages-it-services-engineering-team-js": () => import("./../../../src/pages/it-services/engineering-team.js" /* webpackChunkName: "component---src-pages-it-services-engineering-team-js" */),
  "component---src-pages-it-services-other-services-js": () => import("./../../../src/pages/it-services/other-services.js" /* webpackChunkName: "component---src-pages-it-services-other-services-js" */),
  "component---src-pages-it-services-semantic-ai-data-platform-js": () => import("./../../../src/pages/it-services/semantic-ai-data-platform.js" /* webpackChunkName: "component---src-pages-it-services-semantic-ai-data-platform-js" */),
  "component---src-pages-markets-e-commerce-js": () => import("./../../../src/pages/markets/e-commerce.js" /* webpackChunkName: "component---src-pages-markets-e-commerce-js" */),
  "component---src-pages-markets-industrials-js": () => import("./../../../src/pages/markets/industrials.js" /* webpackChunkName: "component---src-pages-markets-industrials-js" */),
  "component---src-pages-markets-marketing-js": () => import("./../../../src/pages/markets/marketing.js" /* webpackChunkName: "component---src-pages-markets-marketing-js" */),
  "component---src-pages-markets-real-estate-js": () => import("./../../../src/pages/markets/real-estate.js" /* webpackChunkName: "component---src-pages-markets-real-estate-js" */),
  "component---src-pages-our-work-js": () => import("./../../../src/pages/our-work.js" /* webpackChunkName: "component---src-pages-our-work-js" */)
}

